
import { defineComponent } from 'vue';
import Dialog from 'primevue/dialog';
import Galleria from 'primevue/galleria';
import Rating from 'primevue/rating';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import { mapActions } from 'vuex';
import Image from 'primevue/image';
import ProgressSpinner from 'primevue/progressspinner';
import PartsService from '@/services/PartsService';
import Utils from '@/utils/utils';
const partsService = new PartsService(process.env.VUE_APP_ABSTRACTION_API);

export default defineComponent({
  name: 'ProductDialog',
  components: {
    Dialog,
    Galleria,
    Rating,
    InputNumber,
    Button,
    Divider,
    Image,
    ProgressSpinner,
  },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    selectedProduct: {
      type: Object,
      required: true
    },
    customerId: {
      type: String,
      required: true
    }
  },
  computed: {
    totalAmount(): string {
      return (this.productInfo.product_price * this.productQty).toFixed(2);
    },
    numImagesVisible(): number {
      if(this.productInfo.images.length > 3){
        return 3;
      }
      if(this.productInfo.images.length === 1){
        return 1;
      }
      return 2;
    }
  },
  data: () => ({
    loading: false,
    showProductDetails: false,
    productQty: 1,
    productInfo: {
      part_no: '',
      altcode1: '',
      manufactured_part: '',
      rating: 0,
      total_ratings: '0',
      answered_questions: 0,
      product_price: 0,
      resources: [],
      product_characteristics: [] as any,
      images: [] as any,
      wrap_desc: '',
      invloc: [],
    },
    responsiveOptions: [
      {
          breakpoint: '991px',
          numVisible: 4
      },
      {
          breakpoint: '767px',
          numVisible: 3
      },
    ]
  }),
  updated() {
    this.showProductDetails = this.open;
    this.productInfo.part_no = this.selectedProduct.altcode1 || this.selectedProduct.part_number;
    this.productInfo.wrap_desc = this.selectedProduct.desc;
    this.productInfo.product_price = parseFloat(this.selectedProduct.price) || 0;
    this.productQty =
      this.selectedProduct.quantity ||
      parseFloat(this.selectedProduct.sell_qty) ||
      1;
      this.productInfo.manufactured_part = '';
      this.productInfo.product_characteristics = [];
      this.loading = true;

      partsService.getPart(this.selectedProduct.part_number, this.customerId, "spec_desc").then((resp: any) => {
        this.productInfo.manufactured_part = resp.miva_product_id ? resp.miva_product_id : '';
        this.productInfo.product_characteristics = resp.specs_items; 
        if(resp.spec_desc && resp.spec_desc.length > 0) {
          for(let spec = 0; spec < resp.specs_items?.length; spec++) {
            if(resp.spec_desc[spec] != "") {
              this.productInfo.product_characteristics[spec].specs_desc = resp.spec_desc[spec] 
            }
          }
        }
        this.productInfo.resources = resp.external_links
        this.productInfo.images = resp.image_desc_items === undefined ? [] : resp.image_desc_items;
        this.productInfo.images.forEach((image: any) => {
          if(!image.image_path.includes("http")) {
            image.image_path = require("@/assets/images/productDefaultImg.svg")
            image.image_desc = 'Default image'
          }
        });
        
      }).catch(() => {
        this.showProductDetails = false;
        this.addNotification({
          title: 'Error',
          message: 'Part does not exist.',
          type: 'error'
        })
      })
      .finally(() => {
        this.loading = false;
      })
  },
  emits: ['closeProductDialog', 'addProductToCart'],
  methods: {
    ...mapActions({
      addNotification: "notification/add",
    }),
    closeDialog() {
      this.showProductDetails = false;
      this.$emit('closeProductDialog');
    },
    addProductToCart() {
      this.$emit('addProductToCart', {...this.selectedProduct, quantity: this.productQty});
      this.closeDialog();
    },
    calculateStep(part: any) {
      return Utils.calculateQtyStep(part);
    },
  }
})
