
import { defineComponent } from "vue";
import ResultGridView from "@/components/Orders/ResultGridView.vue";
import Button from "primevue/button";
import Carousel from "primevue/carousel";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputNumber from "primevue/inputnumber";
import Search from "@/components/Search.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import TreeSelect from "primevue/treeselect";
import MultiSelect from "primevue/multiselect";
import ProductDialog from "@/components/Orders/ProductDialog.vue";
import Breadcrumb from "primevue/breadcrumb";
import Tag from "primevue/tag";
import { FilterMatchMode } from "primevue/api";
import Utils from "@/utils/utils";

export default defineComponent({
  name: "Pos",
  components: {
    Button,
    Carousel,
    Column,
    DataTable,
    InputNumber,
    Search,
    ResultGridView,
    TreeSelect,
    MultiSelect,
    LoadingSpinner,
    ProductDialog,
    Breadcrumb,
    Tag,
  },
  created() {
    if (window.innerWidth > 991) {
      this.currentScreenSize = "lg";
    } else {
      this.currentScreenSize = "sm";
    }
    this.getPricesByCode();
    if (this.getCats === null || this.getCats.length === 0) {
      this.fetchControls({
        Client: "",
        id: "CAT",
        procedure: "CAT.CONTROL",
        filename: "CONTROL",
        getter: "control/getCategoryItems",
      });
    }
    this.cats = this.getCats;
  },
  mounted() {
    window.addEventListener("resize", this.resizeFilters);
  },
  computed: {
    ...mapState(["pos"]),
    ...mapGetters({
      getCats: "control/getCategoryItems",
      getFilters: "control/getFilterItems",
      getParts: "pos/getParts",
      getOrder: "pos/getCurrentOrder",
      getRegister: "pos/getRegister",
      getCustomer: "customer/getCust",
      getClient: "session/getClient",
    }),
    smallFilterOptionsComputed(): any[] {
      let options = this.getFilters.map((elem: any) => ({
        key: elem.label,
        label: elem.label,
        icon: "pi pi-list",
        children: elem.tags.map((tag: any) => ({
          key: tag.tag,
          label: tag.label,
        })),
      }));
      return options;
    },
    categories(): any {
      if (this.cats.length === 0) {
        return this.getCats;
      }
      return [...this.cats];
    },
    carrouselClass(): string {
      return this.categories.length > 5
        ? "categories-container"
        : "single-category-container";
    },
    updatedParts(): any {
      return this.parts
        .map((part) => {
          const std_price = this.getStdPrice(part);
          const code_price = this.getCodePrice(part);
          if (code_price != "") {
            part["price"] = code_price;
          } else {
            part["price"] = std_price;
          }
          return part;
        })
        .filter((item) => {
          return item.price != "";
        });
    },
  },

  methods: {
    ...mapActions({
      fetchControls: "control/fetchControls",
      addPartToOrder: "pos/addPartToOrder",
      updatePartQty: "pos/updatePartQty",
      fetchPrices: "pos/fetchPrices",
      setControlFilters: "control/setFilters",
    }),
    scrollToFirstItem() {
      const dataTableRef: any = this.$refs.partsTable;
      if (dataTableRef) {
        const firstItemElement = dataTableRef.$el.querySelector(
          ".p-datatable-tbody .p-selectable-row:first-child"
        );

        if (firstItemElement) {
          firstItemElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
    },
    onPage(event: any) {
      this.scrollToFirstItem();
      if (
        (event.page === event.pageCount ||
          event.page === event.pageCount - 1 ||
          event.page === event.pageCount - 2) &&
        this.parts.length == this.rangeEnd
      ) {
        this.onIsSearchingParts(true);
        this.fetchNextPartsRange();
      }
    },
    getPriceParts(resetRange: boolean) {
      if (resetRange) {
        this.rangeStart = 1;
        this.rangeEnd = 100;
        this.page = 0;
      }
      let filters = this.getActualFilters();
      this.fetchPrices({
        code: this.code,
        cat: this.currentCat,
        part_no: this.currentSearch,
        web_cats: filters,
        client: this.getClient,
        customerId: this.getCustomer.cust_id,
        rangeStart: this.rangeStart,
        rangeEnd: this.rangeEnd,
      })
        .then((resp: any) => {
          if (resetRange) {
            this.parts = resp.parts;
          } else {
            this.parts.push(...resp.parts);
          }

          this.updateParts();
          if (resp.filters) {
            this.setControlFilters(resp.filters).then(() => {
              if (this.currentScreenSize === "sm") {
                this.selectedSmallFilterOptions = null;
              } else {
                this.setFilters();
              }
            });
          }
        })
        .finally(() => {
          this.isSearchingParts = false;
        });
    },
    fetchNextPartsRange() {
      this.rangeStart += 100;
      this.rangeEnd += 100;
      this.getPriceParts(false);
    },
    onRowClick(event: any) {
      this.selectedProduct = event.data;
      this.showProductDetails = true;
    },
    closeDialog() {
      this.showProductDetails = false;
    },
    removeMultiselectChip(event: any) {
      if (
        event.originalEvent.srcElement.className ===
        "p-multiselect-token-icon pi pi-times-circle"
      ) {
        this.getFilteredParts();
      }
    },
    rowClass(rowData: any) {
      return rowData.kitList?.length ? "" : "no-expander";
    },
    switchResultView(isList: boolean) {
      this.isResultsListView = isList;
    },
    addItem(part: any) {
      const index = this.getOrder.findIndex((item: any) => {
        return (
          item.part_number === part.part_number && item.price === part.price
        );
      });
      if (index >= 0) {
        this.getOrder[index].quantity += part.quantity;
        this.updatePartQty({ partIndex: index, part: this.getOrder[index] });
      } else {
        if (part.quantity > 0) {
          part["amount"] = part.price;
          this.addPartToOrder(JSON.parse(JSON.stringify(part)));
        }
      }
      part.quantity = this.calculateStep(part);
      this.updatePrice({ value: part.quantity }, part);
    },
    updatePrice(event: any, part: any) {
      if (event && event.value != part.quantity) {
        part.quantity = event.value;
      }
      const std_price = this.getStdPrice(part);
      const code_price = this.getCodePrice(part);
      if (code_price) {
        part["price"] = code_price;
      } else {
        part["price"] = std_price;
      }
    },
    getFilteredParts() {
      this.onIsSearchingParts(true);
      this.getPriceParts(true);
    },
    setFilters() {
      this.selectedFilterOptions = this.getFilters.map((elem: any) => ({
        label: elem.label,
        value: null,
      }));
    },
    clearFilters() {
      if (this.currentScreenSize === "sm") {
        this.selectedSmallFilterOptions = null;
      } else {
        this.setFilters();
      }
      //call price
      this.getPriceParts(true);
    },
    resizeFilters(event: any) {
      if (event.target.innerWidth > 991 && this.currentScreenSize !== "lg") {
        this.currentScreenSize = "lg";
        let filtered = [] as { label: string; value: any }[];
        if (this.selectedSmallFilterOptions !== null) {
          Object.entries(this.selectedSmallFilterOptions).forEach(
            ([key, value]) => {
              filtered.push({ label: key, value: value });
            }
          );
        }
        filtered = filtered.filter((elem) => elem.value.checked === true);
        let filterChange = {} as any;
        filtered.forEach((elem: any) => {
          this.getFilters.forEach((cat: any) => {
            let options = cat.tags.filter((opt: any) => opt.tag === elem.label);
            if (options.length > 0) {
              if (filterChange[cat.label] === undefined) {
                filterChange[cat.label] = { label: cat.label, value: options };
              } else {
                filterChange[cat.label].value.push(options[0]);
              }
            }
          });
        });
        filterChange = Object.values(filterChange);
        this.selectedFilterOptions.forEach((elem: any) => {
          let index = filterChange.findIndex(
            (item: any) => item.label === elem.label
          );
          if (index !== -1) {
            elem.value = filterChange[index].value;
          } else {
            elem.value = null;
          }
        });
      } else if (
        event.target.innerWidth <= 991 &&
        this.currentScreenSize !== "sm"
      ) {
        this.currentScreenSize = "sm";
        let filtered = this.selectedFilterOptions.filter(
          (elem) => elem.value !== null
        );
        this.selectedSmallFilterOptions = {};
        filtered.forEach((elem) => {
          if (elem.value !== null) {
            elem.value.forEach((tag: any) => {
              this.selectedSmallFilterOptions[tag.tag] = {
                checked: true,
                partialSelected: false,
              };
            });
          }
        });
      }
    },
    getSmallFilterSelectedOptions() {
      let filtered = [] as { label: string; value: any }[];
      if (this.selectedSmallFilterOptions !== null) {
        Object.entries(this.selectedSmallFilterOptions).forEach(
          ([key, value]) => {
            filtered.push({ label: key, value: value });
          }
        );
      }
      filtered = filtered.filter((elem) => elem.value.checked === true);
      let filterSelection = [] as any;
      this.smallFilterOptionsComputed.forEach((tagcat) => {
        let tagByCat = [] as any;
        filtered.forEach((tag) => {
          if (tagcat.children.find((e: any) => e.key === tag.label)) {
            tagByCat.push(tag.label);
          }
        });
        filterSelection.push(tagByCat);
      });
      return filterSelection;
    },
    getFilterSelectedOptions() {
      const filtered = this.selectedFilterOptions.filter(
        (elem) => elem.value !== null
      );
      let selectedOptions = [] as any;
      filtered.forEach((elem) => {
        let tagByCat = [] as any;
        if (elem.value !== null) {
          elem.value.forEach((tag: any) => {
            tagByCat.push(tag.tag);
          });
        }
        selectedOptions.push(tagByCat);
      });
      return selectedOptions;
    },
    getActualFilters() {
      if (this.currentScreenSize === "sm") {
        return this.getSmallFilterSelectedOptions();
      } else if (this.currentScreenSize === "lg") {
        return this.getFilterSelectedOptions();
      }
    },
    onIsSearchingParts(event: boolean) {
      this.isSearchingParts = event;
    },
    lookupPrice(input: any, searchComponent: any) {
      if (input) {
        this.page = 0;
        this.rangeStart = 1;
        this.rangeEnd = 100;

        this.fetchPrices({
          code: this.code,
          cat: this.currentCat,
          part_no: input,
          client: this.getClient,
          customerId: this.getCustomer.cust_id,
          rangeStart: this.rangeStart,
          rangeEnd: this.rangeEnd,
        })
          .then((resp: any) => {
            searchComponent.$emit("priceSearch", {
              search: input,
              parts: resp.parts,
            });
          })
          .finally(() => {
            (searchComponent.$refs.autocomplete as any).searching = false;
          });
      }
    },
    async getPriceSearchResult(result: any) {
      this.isSearchingParts = false;
      this.currentSearch = await result.search;
      this.currentCat = "";
      this.parts = result.parts;
      this.updateParts();
      if (result.control_items) {
        this.setControlFilters(result.control_items[0]?.web_category_items);
      }
    },
    getCodePrice(price: any) {
      const quantity = price.quantity || 1;
      const code_price = price.code_items?.find(
        (item: any) => item.code === this.code
      );
      let price_value = "";
      if (code_price && code_price.code_price_items) {
        if (code_price.code_price_items.length === 1) {
          price_value = code_price.code_price_items[0].code_price;
        } else {
          code_price.code_price_items.forEach((code_item: any) => {
            const code_quantity = parseFloat(code_item.code_qty);
            if (quantity >= code_quantity) {
              price_value = code_item.code_price;
            }
          });
        }
      }

      if (price_value) {
        return price_value;
      }
      return "";
    },
    updateParts() {
      if (this.parts) {
        this.parts = this.parts
          .map((part) => {
            const std_price = this.getStdPrice(part);
            const code_price = this.getCodePrice(part);
            if (code_price) {
              part["price"] = code_price;
            } else {
              part["price"] = std_price;
            }
            part["quantity"] = this.calculateStep(part);
            return part;
          })
          .filter((item) => {
            return item.price != "";
          });
      }
      Utils.focusAndCenterElement(this.$refs["parts-table"]);
    },
    getStdPrice(price: any) {
      const quantity = price.quantity || 1;
      let price_value = "";
      if (price.std_price_items) {
        if (price.std_price_items.length === 1) {
          price_value = price.std_price_items[0].std_price;
        } else {
          price.std_price_items.forEach((std_item: any) => {
            const std_quantity = parseFloat(std_item.std_qty);
            if (quantity >= std_quantity) {
              price_value = std_item.std_price;
            }
          });
        }

        // If there is no valid price with quantitys default to first option.
        if (price_value === "") {
          price_value = price.std_price_items[0].std_price;
        }
      }
      if (price_value) {
        return price_value;
      }
      return "0";
    },
    getPricesByCode() {
      if (this.getCustomer) {
        this.code = this.getCustomer.code || this.getCustomer.cust_id;
        this.getPriceParts(true);
      }
    },
    calculateStep(part: any) {
      return Utils.calculateQtyStep(part);
    },
    formatPrice(amount: number | string) {
      return Utils.formatPrice(amount);
    },
    buttonColor(quantity: number) {
      return quantity > 0
        ? "background-color: var(--default-button-color)"
        : "background-color: grey";
    },
    handleCrumbClick(cat: any) {
      this.onIsSearchingParts(true);
      this.currentSearch = "";
      this.getPriceParts(true);
      let result = this.findCategory(this.getCats, this.currentCat, cat);
      this.cats = result;
      this.carouselPage = 0;
      this.currentCat = cat;
      let newBreadcrumbs: any[] = [];
      for (let cat of this.partSearch) {
        newBreadcrumbs.push(cat);
        if (cat.code === this.currentCat) {
          this.partSearch = newBreadcrumbs;
          break;
        }
      }
    },
    findCategory(
      categories: any,
      startingCategory: string,
      targetCategory: string
    ): any {
      if (startingCategory === targetCategory) {
        return this.cats;
      }
      for (const categoryInfo of categories) {
        if (categoryInfo.category === targetCategory) {
          return categoryInfo.subcategories || [];
        }
        if (categoryInfo.subcategories) {
          const foundList = this.findCategory(
            categoryInfo.subcategories,
            startingCategory,
            targetCategory
          );
          if (foundList) {
            if (foundList.length > 0) {
              return foundList;
            }
          }
        }
      }
      return null;
    },
    searchCategory(data: any) {
      if (this.partSearch.length !== 0) {
        if (
          this.cats.find(
            (e: any) =>
              e.category === this.partSearch[this.partSearch.length - 1].code
          )
        ) {
          this.partSearch.pop();
        }
      }
      if (data.subcategories) {
        this.cats = data.subcategories;
      }

      this.partSearch.push({
        label: data.description || data.category,
        code: data.category,
        command: ({ originalEvent }: any) => {
          this.handleCrumbClick(data.category);
          originalEvent.preventDefault();
        },
      });
      this.onIsSearchingParts(true);
      this.currentSearch = "";
      this.currentCat = data.category;

      this.getPriceParts(true);

      this.carouselPage = 0;
    },
    handleOnHandTagText(avail_qty: number) {
      return avail_qty > 0 ? "In Stock" : "Out of Stock";
    },
    handleOnHandTagColor(avail_qty: number) {
      return avail_qty > 0 ? "success" : "danger";
    },
    setCarouselResponsiveOptions() {
      if (this.categories.length > 5) {
        this.responsiveOptions = [
          {
            breakpoint: "1540px",
            numVisible: 10,
            numScroll: 4,
          },
          {
            breakpoint: "1199px",
            numVisible: 8,
            numScroll: 2,
          },
          {
            breakpoint: "870px",
            numVisible: 5,
            numScroll: 2,
          },
          {
            breakpoint: "700px",
            numVisible: 3,
            numScroll: 1,
          },
          {
            breakpoint: "525px",
            numVisible: 2,
            numScroll: 1,
          },
        ];
      } else {
        this.responsiveOptions = [
          {
            breakpoint: "1540px",
            numVisible: 5,
            numScroll: 1,
          },
          {
            breakpoint: "1199px",
            numVisible: 7,
            numScroll: 1,
          },
          {
            breakpoint: "870px",
            numVisible: 5,
            numScroll: 1,
          },
          {
            breakpoint: "700px",
            numVisible: 3,
            numScroll: 1,
          },
          {
            breakpoint: "525px",
            numVisible: 1,
            numScroll: 1,
          },
        ];
      }
    },
  },
  data() {
    return {
      showProductDetails: false,
      selectedProduct: {} as any,
      isSearchingParts: false,
      isResultsListView: true,
      currentScreenSize: "",
      selectedSmallFilterOptions: {} as any,
      selectedFilterOptions: [] as { label: string; value: any[] | null }[],
      expandedRows: [] as Array<string>,
      partSearch: [] as any,
      carouselPage: 0,
      currentCat: "",
      currentSearch: "",
      code: "",
      init: {
        label: "Categories",
        command: ({ originalEvent }: any) => {
          this.currentCat = "" as any;
          this.cats = this.getCats;
          this.partSearch = [] as any;
          this.getPricesByCode();
          originalEvent.preventDefault();
        },
      },
      parts: [] as Array<any>,
      rangeStart: 1,
      rangeEnd: 100,
      page: 0,
      rows: 25,
      filters: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      cats: [],
      responsiveOptions: [
        {
          breakpoint: "1540px",
          numVisible: 10,
          numScroll: 2,
        },
        {
          breakpoint: "1199px",
          numVisible: 7,
          numScroll: 1,
        },
        {
          breakpoint: "870px",
          numVisible: 5,
          numScroll: 1,
        },
        {
          breakpoint: "700px",
          numVisible: 3,
          numScroll: 1,
        },
        {
          breakpoint: "525px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
  watch: {
    categories() {
      this.setCarouselResponsiveOptions();
    },
    getFilters(newVal, oldVal) {
      this.setFilters();
    },
  },
});
